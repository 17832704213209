import { template as template_23658c6bf1634fa9aaba970b3d74729f } from "@ember/template-compiler";
const SidebarSectionMessage = template_23658c6bf1634fa9aaba970b3d74729f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
