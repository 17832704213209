import { template as template_3875085d623749dba5dd4b37603a7fff } from "@ember/template-compiler";
const FKLabel = template_3875085d623749dba5dd4b37603a7fff(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
