import { template as template_bccf79e6a0574fd0a4b6d8bf1af8cde7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bccf79e6a0574fd0a4b6d8bf1af8cde7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
